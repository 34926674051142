<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn flat class="btn btn-white px-5 mt-3 font-sys" large v-on="on">
          Editar cards
          <i class="ml-3 fal fa-pen-square"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Card de Foto</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog=false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px;">
          <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
            <v-flex md5 xs12 class="pt-4 border-horizontal display-flex f-d-column">
              <v-btn
                flat
                round
                color="white"
                class="d-flex ml-auto btn btn-primary p-425-0-25 px-4 mb-2"
                large
                @click="create"
                :disabled="selectedPhotoCard == 0"
              >
                <i class="far fa-plus mr-2"></i>Novo card
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal flex"
                  :class="{ 'active': selectedPhotoCard === c.id }"
                  @click="selectPhotoCard(c.id)"
                  v-for="c in photoCardList"
                  :key="c.id"
                >
                  <div class="mr-3">
                    <FileImage 
                    :file="c.file" 
                    :alt="''"
                    effects="c_crop,g_face:auto,z_0.4,h_300,w_300"
                    />
                  </div>
                  <div>
                    <div>{{ c.title }}</div>
                    <span v-html="c.subtitle"></span>
                  </div>
                </div>
              </div>
            </v-flex>
            <v-flex class="box-heart-father" md7 xs12 :class="{active: showForm}">
              <div class="list-scroll">
                <div
                  class="bg-news-modal"
                  id="preview"
                  ref="preview"
                  :style="'background-image:' + getImage()"
                >
                  <Input
                    style="height: 100%; z-index:10"
                    type="file"
                    id="upload-cases-image"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_image"
                  />
                  <label for="upload-cases-image">
                    <i class="fal fa-cloud-upload mr-2"></i>
                    Imagem
                  </label>
                </div>
                <div class="px-4 mt-3">
                  <InputText
                    type="text"
                    place-holder="Título do card"
                    textLabel="Título"
                    v-model="photoCard.title"
                  />
                  <InputText
                    type="text"
                    place-holder="Subtítulo do card"
                    textLabel="Título"
                    v-model="photoCard.subtitle"
                  />
                  <vue-editor v-model="photoCard.text" :editor-toolbar="customToolbar" />
                </div>
              </div>
              <v-flex class="align-center justify-space-between footer-step-modal">
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="btn-back-modal d-flex theme--light btn-default px-0 ma-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="openConfirmDeleteCaseModal(photoCard.id)"
                  :disabled="selectedPhotoCard == 0"
                >Excluir</v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="save"
                  :disabled="!isValid"
                >Salvar</v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir esse card?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedPhotoCard)"
          />
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import FileImage from "@/components/FileImage.vue";
import File from "@/scripts/models/file.model";
import CampaignPhotoCard from "@/scripts/models/campaignPhotoCard.model";
import CampaignService from "@/scripts/services/campaign.service";
import CommonHelper from "@/scripts/helpers/common.helper";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    AucAlert,
    VueEditor,
    FileImage
  },
  props: {
    value: Array,
    campaignId: 0
  },
  data() {
    return {
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }]],
      campaignService: new CampaignService(),
      photoCard: new CampaignPhotoCard(),
      photoCardList: this.value,
      selectedPhotoCard: null,
      showForm: true,
      dialog: false
    };
  },
  computed: {
    isValid() {
      // if (
      //   this.photoCard &&
      //   this.photoCard.title &&
      //   this.photoCard.text
      // )
      //   return true;
      // return false;
      return true;
    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.create();
      }
    }
  },
  methods: {
    openConfirmDeleteCaseModal(id) {
      this.selectedPhotoCard = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listPhotoCardsByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.photoCardList = data;
      this.$emit("input", data);
    },
    selectPhotoCard(photoCardId) {
      let photoCard = this.photoCardList.find(c => c.id == photoCardId);
      this.selectedPhotoCard = photoCardId;
      this.copy(photoCard);
      // console.log(photoCard)
    },
    copy(photoCard) {
      this.photoCard = new CampaignPhotoCard();
      this.photoCard.id = photoCard.id;
      this.photoCard.campaignId = photoCard.campaignId;
      this.photoCard.title = photoCard.title;
      this.photoCard.subtitle = photoCard.subtitle;
      this.photoCard.text = photoCard.text;
      if(this.photoCard.file == null)
        this.photoCard.file = new File();

      if (photoCard && photoCard.file) {
        this.photoCard.file.id = photoCard.file.id;
        this.photoCard.file.userId = photoCard.file.userId;
        this.photoCard.file.name = photoCard.file.name;
        this.photoCard.file.path = photoCard.file.path;
        this.photoCard.file.size = photoCard.file.size;
        this.photoCard.file.extension = photoCard.file.extension;
        this.photoCard.file.origin = photoCard.file.origin;
        this.photoCard.file.publicId = photoCard.file.publicId;
        if (
          photoCard.file != null &&
          photoCard.file.hasOwnProperty("imageContent")
        ) {
          this.photoCard.file.imageContent = photoCard.file.imageContent;
        }
      }  
    },
    create() {
      this.selectedPhotoCard = 0;
      this.photoCard = new CampaignPhotoCard();
      CommonHelper.clearInputFile(this.$refs.upload_image);
    },
    getImage() {
      if (
        this.photoCard == null ||
        this.photoCard.file == null ||
        !this.photoCard.file.hasOwnProperty("imageContent")
      )
        return "";
      return "url(" + this.photoCard.file.path + ")";
    },
    fileChanged() {
      var file = this.$refs.upload_image.files[0];
      let reader = new FileReader();
      this.photoCard.file.name = file.name;
      reader.onload = function() {
        this.photoCard.file.path = reader.result;
        this.$set(this.photoCard.file, "imageContent", reader.result);
      }.bind(this);
      reader.readAsDataURL(file);
    },
    save() {
      this.photoCard.campaignId = this.campaignId;
      this.campaignService.savePhotoCards(this.saveCallback, this.photoCard);
    },
    saveCallback(data) {
      this.listByCampaignId();
      this.create();
    },
    remove(selectedPhotoCard) {
      this.campaignService.deletePhotoCards(this.removeCallback, selectedPhotoCard);
    },
    removeCallback() {
      this.listByCampaignId();
      this.create();
    }
  }
};
</script>